import { reactive } from 'vue';
import wx from 'weixin-js-sdk';
import memberApi from '@/api/memberApi'
import filesApi from "@/api/filesApi";
import goodsPrictureApi from "@/api/goodsPictureApi";
import { Toast } from 'vant';
const wxModel = (refresh) => {
    const wxOptions = reactive({
        config: {

        },
        picList: [],
        files: new FormData(),
        result: {
            uploaded: 1,
            urls: []
        },
        shares: [
            { name: '微信', icon: 'wechat' },
            { name: '朋友圈', icon: 'wechat-moments' },
            { name: '分享海报', icon: 'poster' },
            { name: '二维码', icon: 'qrcode' },
        ],
        base64: '',
        share: {
            title: '', // 分享标题
            desc: '', // 分享描述
            link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: '', // 分享图标
            type: 'link', // 分享类型,music、video或link，不填默认为link
            dataUrl: '',
        },
        fileUrls: [],
        goodsPrictures: [],
        picType: 'rotation',//list,rotation,default
        message: '',
        num: 0,
        percentage: 0,
        gradientColor: {
            '0%': '#3fecff',
            '100%': '#6149f6',
        },
        isWxMessageShow: false,
        isShowShare: false,
    })
    async function initConfig() {
        await memberApi.getWxConfig().then(res => {
            if (res.status == 200) {
                wxOptions.config = res.result;
            }
        })
        wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: wxOptions.config.appId, // 必填，公众号的唯一标识
            timestamp: wxOptions.config.timestamp, // 必填，生成签名的时间戳
            nonceStr: wxOptions.config.nonceStr, // 必填，生成签名的随机串
            signature: wxOptions.config.signature,// 必填，签名
            jsApiList: [
                'checkJsApi',
                'updateAppMessageShareData',
                'updateTimelineShareData',
                'onMenuShareTimeline',
                'onMenuShareAppMessage',
                'onMenuShareQQ',
                'scanQRCode',
                'chooseImage',
                'uploadImage',
                'previewImage',
                'downloadImage',
                'getLocalImgData',
                'chooseVideo',
                'uploadVideo'
            ]
        });
        wx.ready(function () {
            console.log("ready");
        })
        wx.error(function (res) {
            // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
            console.log("error", JSON.stringify(res));
        });
        wx.checkJsApi({
            jsApiList: ['getLocalImgData', 'chooseImage', 'chooseVideo'], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
            success: function (res) {
                // 以键值对的形式返回，可用的 api 值true，不可用为false
                // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                //Toast('checkJsApi====' + JSON.stringify(res));
                console.log(res);
            }
        });
    }
    async function initShareConfig(share) {
        await memberApi.getWxConfig().then(res => {
            if (res.status == 200) {
                wxOptions.config = res.result;
            }
        })
        wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: wxOptions.config.appId, // 必填，公众号的唯一标识
            timestamp: wxOptions.config.timestamp, // 必填，生成签名的时间戳
            nonceStr: wxOptions.config.nonceStr, // 必填，生成签名的随机串
            signature: wxOptions.config.signature,// 必填，签名
            jsApiList: [
                'checkJsApi',
                'updateAppMessageShareData',
                'updateTimelineShareData',
                'onMenuShareTimeline',
                'onMenuShareAppMessage',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'onMenuShareWeibo'
            ]
        });
        wx.ready(function () {
            console.log("ready");
            shareMessage(share);
            shareTimeline(share);
        })
        wx.error(function (res) {
            // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
            console.log("error", JSON.stringify(res));
        });
        wx.checkJsApi({
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
            success: function (res) {
                // 以键值对的形式返回，可用的 api 值true，不可用为false
                // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                //Toast('checkJsApi====' + JSON.stringify(res));
                console.log(res);
            }
        });
    }
    function chooseImage(type, count = 1, picture) {
        wxOptions.percentage = 0;
        wxOptions.num = 0;
        wx.chooseImage({
            count: count, // 默认9
            sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
            sourceType: ['album', 'camera'], //是相机
            success: function (res) {
                //Toast(type + '===chooseImage success:====' + JSON.stringify(res));
                wxOptions.picList = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                if (wxOptions.picList.length > 0) {
                    doImageList(picture);
                }
            },
            fail: function (res) {
                console.log('chooseImage fail:====' + JSON.stringify(res));
            }
        });
    }
    function chooseDefaultImage(type, count = 1) {
        wxOptions.percentage = 0;
        wxOptions.num = 0;
        wx.chooseImage({
            count: count, // 默认9
            sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
            sourceType: ['camera'], //是相机
            success: function (res) {
                //Toast(type + '===chooseImage success:====' + JSON.stringify(res));
                wxOptions.picList = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                if (wxOptions.picList.length > 0) {
                    doImageBackList();
                }
            },
            fail: function (res) {
                console.log('chooseImage fail:====' + JSON.stringify(res));
            }
        });
    }
    async function doImageList(picture) {
        wxOptions.isWxMessageShow = true;
        wxOptions.num=0;
        wxOptions.percentage = 0;
        wxOptions.goodsPrictures=[];
        for(var i = 0; i < wxOptions.picList.length; i++){
            await wxUploadImg(wxOptions.picList[i], picture).then(res => {
                doLoop(res);
            })
        }
    }
    function doImageBackList() {
        wxOptions.isWxMessageShow = true;
        wxOptions.percentage = 0;
        wxOptions.picList.forEach(async (item) => {
            await wxUploadImgBackList(item).then(res => {
                doLoopList(res);
            })
        })

    }
    function doLoop(res) {
        wxOptions.num = parseInt(wxOptions.num) + 1;
        if (res.status == 200) {
            wxOptions.message = '第' + wxOptions.num + '幅，上传完成.';
        } else {
            wxOptions.message = '第' + wxOptions.num + '幅，已上传过了！';
        }
        wxOptions.percentage = Math.round(parseInt(wxOptions.num) / wxOptions.picList.length * 100);
        if (wxOptions.percentage == 100) {
            wxOptions.isWxMessageShow = false;
            refresh();
        }
    }

    function doLoopList(res) {
        wxOptions.num = parseInt(wxOptions.num) + 1;
        if (res.status == 200) {
            wxOptions.message = '第' + wxOptions.num + '幅，上传完成.';
        } else {
            wxOptions.message = '第' + wxOptions.num + '幅，已上传过了！';
        }
        wxOptions.percentage = Math.round(parseInt(wxOptions.num) / wxOptions.picList.length * 100);
        if (wxOptions.percentage == 100) {
            //alert(res.result.url);
            wxOptions.isWxMessageShow = false;
            refresh();

        }
    }

    function chooseVideo() {
        wx.chooseVideo({
            sourceType: ['album', 'camera'],
            maxDuration: "6",
            camera: 'front',
            success: function (res) {
                var localId = res.localIds
                console.log(localId);
                alert(JSON.parse(res));
            }
        })
    }
    function wxUploadImg(item, picture) {
        return new Promise((resolve) => {
            if (item.indexOf("wxlocalresource") != -1) {
                item = item.replace("wxlocalresource", "wxLocalResource");
            }
            wx.uploadImage({
                localId: item.toString(), // 需要上传的图片的本地ID，由 chooseImage 接口获得
                isShowProgressTips: 0, // 默认为1，显示进度提示
                success: function (res) {
                    var serverId = res.serverId; // 返回图片的服务器端ID
                    filesApi.wxDownload({ mediaId: serverId, type: 'goods' }).then(res => {
                        if (res.status == 200) {
                            //wxOptions.fileUrls.push(res.result.url);
                            picture.pictureMiddle = res.result.url;
                            picture.ortherId = item.toString();
                            goodsPrictureApi.add(picture).then(res1=>{
                                resolve(res1);
                            })
                        }
                    })
                }
            });
        });
    }
    function wxUploadImgBackList(item) {
        return new Promise((resolve) => {
            item = item.replace("wxlocalresource", "wxLocalResource");
            wx.uploadImage({
                localId: item.toString(), // 需要上传的图片的本地ID，由 chooseImage 接口获得
                isShowProgressTips: 0, // 默认为1，显示进度提示
                success: function (res) {
                    var serverId = res.serverId; // 返回图片的服务器端ID
                    filesApi.wxDownload({ mediaId: serverId, type: 'order' }).then(res => {
                        if (res.status == 200) {
                            wxOptions.fileUrls.push({ url: res.result.url });
                            resolve(res);
                        }
                    })
                }
            });
        });
    }
    function shareMessage(setConfig) {
        wx.updateAppMessageShareData({
            title: setConfig.title, // 分享标题
            desc: setConfig.desc, // 分享描述
            link: setConfig.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: setConfig.imgUrl, // 分享图标
            success: function () {
                // 用户点击了分享后执行的回调函数
                wxOptions.isShowShare = false;
            }
        });
        wx.onMenuShareAppMessage({
            title: setConfig.title, // 分享标题
            desc: setConfig.desc, // 分享描述
            link: setConfig.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: setConfig.imgUrl, // 分享图标
            success: function () {
                // 用户点击了分享后执行的回调函数
                wxOptions.isShowShare = false;
            }
        });
    }

    function shareTimeline(setConfig) {
        wx.updateTimelineShareData({
            title: setConfig.title, // 分享标题
            link: setConfig.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: setConfig.imgUrl, // 分享图标
            success: function () {
                // 用户点击了分享后执行的回调函数
                wxOptions.isShowShare = false;
            }
        });
        wx.onMenuShareTimeline({
            title: setConfig.title, // 分享标题
            link: setConfig.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: setConfig.imgUrl, // 分享图标
            success: function () {
                // 用户点击了分享后执行的回调函数
                wxOptions.isShowShare = false;
            }
        });
    }

    async function uploadImg(param) {
        var url = "";
        await filesApi.uploadImg(param).then((res) => {
            if (res.status == 200) {
                Toast(JSON.stringify(res));
                url = res.result.url;
            }
        });
        return url;
    }
    return {
        wxOptions,
        initConfig,
        chooseImage,
        uploadImg,
        chooseVideo,
        shareMessage,
        shareTimeline,
        initShareConfig,
        chooseDefaultImage
    }
}

export default wxModel;