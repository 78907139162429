import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

const baseurl = config.basepath+'gc/';
// const baseurl = 'http://localhost:8022/';
const goodsPrictureApi = {
    add: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/pricture/add', params)
    },
    addList: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/pricture/addList', params)
    },
    update: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/pricture/update', params)
    },
    get: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/pricture/get', params)
    },
    del: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/pricture/del', params)
    },
    getPrictureForGoodsId: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/pricture/getPrictureForGoodsId', params)
    },
}

export default goodsPrictureApi