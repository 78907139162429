import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

const baseurl = config.basepath;
const filesApi = {

   uploadImg: (params) => {
      return ajaxHandle(baseurl, 'upload', '/file/member/api/image/upload', params)
   },
   uploadFormImg: (params) => {
      return ajaxHandle(baseurl, 'uploadForm', '/file/member/api/image/upload', params)
   },
   uploadFile: (params) => {
      return ajaxHandle(baseurl, 'upload', '/file/member/api/file/upload', params)
   },
   uploadFileFrom: (params) => {
      return ajaxHandle(baseurl, 'uploadForm', '/file/member/api/file/upload', params)
   },
   uploadVideo: (params) => {
      return ajaxHandle(baseurl, 'upload', '/file/member/api/video/upload', params)
   },
   wxDownload: (params) => {
      return ajaxHandle(baseurl, 'post', '/file/member/api/image/wx/download', params)
   },
}

export default filesApi