<template>
  <van-sticky :offset-bottom="50" @scroll="topShow" position="bottom">
    <div class="totop" v-if="options.isTopShow">
      <a href="javascript:scrollTo(0,0)"><img src="images/totop.png" /></a>
    </div>
  </van-sticky>
</template>

<script>
import { reactive } from "@vue/reactivity";
export default {
  setup() {
    const options = reactive({
      isTopShow: false,
    });
    const topShow = (val) => {
      if (val.scrollTop > 300) {
        options.isTopShow = true;
      } else {
        options.isTopShow = false;
      }
    };
    return {
      options,
      topShow,
    };
  },
};
</script>

<style>
</style>