import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

const baseurl = config.basepath;
const memberApi = {
    update: (params) => {
        return ajaxHandle(baseurl, 'post', 'customer/api/update', params)
    },
    updateLoginName: (params) => {
        return ajaxHandle(baseurl, 'post', 'customer/api/updateLoginName', params)
    },
    updateNickName: (params) => {
        return ajaxHandle(baseurl, 'post', 'customer/api/updateNickName', params)
    },
    updateCityName: (params) => {
        return ajaxHandle(baseurl, 'post', 'customer/api/updateCityName', params)
    },
    updatePhone: (params) => {
        return ajaxHandle(baseurl, 'post', 'customer/api/updatePhone', params)
    },
    uploadLogo: (params) => {
        return ajaxHandle(baseurl, 'post', 'customer/api/uploadLogo', params)
    },
    updatePwd: (params) => {
        return ajaxHandle(baseurl, 'post', 'customer/api/updatePwd', params)
    },
    getInfo: (params) => {
        return ajaxHandle(baseurl, 'post', 'customer/api/getInfo', params)
    },
    logout: () => {
        return ajaxHandle(baseurl, 'post', 'customer/api/logout', {})
    },
    getWxConfig: () => {
        return ajaxHandle(baseurl, 'post', 'customer/wx/config/info', {})
    },
}

export default memberApi